.custom-shape-divider-bottom-1734110231 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1734110231 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 183px;
}

.custom-shape-divider-bottom-1734110231 .shape-fill {
    fill: #fce8ce;
}