.swiper-slide {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: top;
}

.swiper-slide {
  width: 50%;
  border-radius: 12;
}

.swiper-slide-drink {
  width: 100%;
  border-radius: 12;
}
